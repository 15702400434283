import React from 'react';

const RotateIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" fill="none" viewBox="0 0 24 20">
      <path
        fill="#000"
        d="M11.887 19.526c-3.992 0-7.592-2.526-8.96-6.284a.502.502 0 11.944-.344 8.561 8.561 0 008.016 5.623c4.708 0 8.538-3.83 8.538-8.538a.502.502 0 011.004 0c0 5.262-4.28 9.543-9.542 9.543zM2.846 10.486a.502.502 0 01-.502-.503c0-5.261 4.281-9.542 9.543-9.542 3.992 0 7.592 2.525 8.96 6.284a.502.502 0 11-.944.343 8.561 8.561 0 00-8.016-5.623c-4.708 0-8.538 3.83-8.538 8.538a.502.502 0 01-.503.502z"></path>
      <path
        fill="#000"
        d="M2.847 11.49a.504.504 0 01-.356-.147L.482 9.334a.503.503 0 01.71-.71l1.655 1.654L4.5 8.624a.503.503 0 01.71.71l-2.009 2.01a.5.5 0 01-.354.146zM22.936 11.49a.505.505 0 01-.355-.147L20.927 9.69l-1.653 1.653a.503.503 0 01-.71-.71l2.009-2.009a.503.503 0 01.71 0l2.009 2.01a.503.503 0 01-.356.857z"></path>
    </svg>
  );
};

export default React.memo(RotateIcon);

import RotateIcon from '@components/bits/icons/RotateIcon';
import SanityImage from '@components/bits/SanityImage/SanityImage';
// import SanityImage from '@components/bits/SanityImage/SanityImage';
import React, { FunctionComponent, useEffect, useState } from 'react';

import styles from './index.module.scss';

type Props = {
  car;
  selectedAttributes;
  carViewImage;
  setCarViewImage;
};

const CarView: FunctionComponent<Props> = ({
  car,
  selectedAttributes,
  carViewImage,
  setCarViewImage,
}): JSX.Element => {
  const [useImage, setImage] = useState(null);
  const {
    content: { carInfo },
  } = car;

  useEffect(() => {
    setImage(selectedAttributes?.color?.image || carInfo?.figure);
  }, [selectedAttributes]);

  return (
    <div className={styles.root} onClick={() => setCarViewImage(!carViewImage)}>
      <div className={styles['_navigation']}>
        <RotateIcon />
      </div>
      {!carViewImage && useImage ? <SanityImage layout="fill" image={useImage} /> : null}
      {carViewImage && carInfo?.interiorFigure ? (
        <SanityImage layout="fill" image={carInfo?.interiorFigure} />
      ) : null}
    </div>
  );
};

export default CarView;

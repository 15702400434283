import { toSubaruNumber } from '@utils/convert';
import React, { FunctionComponent } from 'react';

import styles from './selectionListElement.module.scss';

type Props = {
  type;
  title;
  subtitle;
  price?;
}

const SelectionListElement: FunctionComponent<Props>  = ({ type, title, subtitle, price }) => {
  return (
    <div className={styles['root']}>
        <div>
            <h5>{`${type}: ${title}`}</h5>
            <p>{subtitle}</p>
        </div>
        {price !== null && <p>{`${toSubaruNumber(price)},- NOK *`}</p>}
    </div>
  );
};



export default SelectionListElement;


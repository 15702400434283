import React, { LegacyRef, memo, MutableRefObject } from 'react';

import Gtx from './Gtx';
import styles from './index.module.scss';

// interface Size {
//   width: number | undefined;
//   height: number | undefined;
// }
type Props = {
  data;
  loadGutterImages?: boolean;
  ref: MutableRefObject<unknown>;
  currentRow: {
    curr: number;
    nextId: number;
    previous: number;
    previousGutter: number;
  };
};

const Element = React.forwardRef((props: Props, ref: LegacyRef<HTMLDivElement>) => {
  const { data, currentRow } = props;

  return (
    <div ref={ref} className={styles['root']}>
      {
        data?.map((val, key) => {
          return <Gtx key={key} rowID={key} currentRow={currentRow} {...val} />
        })
      }
    </div>
  );
});

Element.displayName = "Element";

const Memosed = memo(Element, (prevProps, nextProps) => {
  return prevProps.currentRow === nextProps.currentRow
})

export default Memosed;

import ArrowWithCircle from '@components/bits/icons/ArrowWithCircle';
import SimpleBlockContent from '@components/bits/SimpleBlockContent';
import SquareButton from '@components/bits/SquareButton';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import CarView from './CarView';
import styles from './index.module.scss';
import Step from './Step';
import Description from './Step/types/bits/Description';
import SelectionListElement from './Step/types/bits/SelectionListElement';
import TotalPrice from './Step/types/bits/TotalPrice';

type Props = {
  steps;
  car;
  total;
  disclamer;
  details;
  form?;
};

type SelectedProps = {
  model?;
  color?;
  accessories?;
  finans?;
  assistant?;
  totalCost?;
};

const BuildCar: FunctionComponent<Props> = ({ car, steps, total, disclamer, details, form }) => {
  const [infoPanelOpen, setInfoPanelOpen] = useState(null);
  const [carViewImage, setCarViewImage] = useState(0);
  const [detailsPanelOpen, setDetailsPanelOpen] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [tick, setTick] = useState(0);
  const [totalCost, setTotalCost] = useState(0);

  const selectedAttributes = useRef<SelectedProps>({});
  const { model, color, accessories, finans, assistant } = selectedAttributes.current;

  const updateActiveStep = (stepID) => {
    setActiveStep(activeStep !== stepID ? stepID : 0);
  };

  const calculateTotalCost = () => {
    const modelCost = model?.price || 0;
    const colorCost = color?.price || 0;

    const accessoriesSum = accessories
      ? Object.keys(accessories).reduce(function (previous, key) {
          return previous + accessories[key].price;
        }, 0)
      : 0;

    const price = modelCost + colorCost + accessoriesSum;

    return price;
  };

  const updateSelection = (data) => {
    const update = Object.assign({}, selectedAttributes.current, data);
    selectedAttributes.current = update;
    setTick(tick + 1);
  };

  const openDisclamer = (element) => {
    setInfoPanelOpen(element);
  };

  useEffect(() => {
    const tc = calculateTotalCost();
    setTotalCost(tc);
  }, [selectedAttributes, tick]);

  useEffect(() => {
    if (infoPanelOpen) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'auto';
    }
  }, [infoPanelOpen]);

  let i = 1;

  return (
    <>
      {infoPanelOpen && (
        <Description
          element={infoPanelOpen}
          setInfoPanelOpen={setInfoPanelOpen}
          updateSelection={updateSelection}
        />
      )}
      <div className={styles.root}>
        <div className={styles['_wrapper']}>
          <div className={styles['_content']}>
            <div id="buildCarScrollContainer" className={styles['_configuration']}>
              <div className={styles['_desc']}>
                <h2>{steps ? steps.title : 'Tittel mangler'}</h2>
                <p>{steps ? steps.desc : 'ingress mangler'}</p>
              </div>
              <div className={styles['_accordion-wrapper']}>
                {steps.model?.step?.available && (
                  <Step
                    data={steps.model}
                    car={car}
                    number={i}
                    nextStep={++i}
                    activeStep={activeStep}
                    setActiveStep={updateActiveStep}
                    selectedAttributes={selectedAttributes.current}
                    updateSelection={updateSelection}
                    setDisclamerPanelOpen={openDisclamer}
                  />
                )}
                {steps.color?.step?.available && (
                  <Step
                    data={steps.color}
                    car={car}
                    number={i}
                    nextStep={++i}
                    activeStep={activeStep}
                    setActiveStep={updateActiveStep}
                    selectedAttributes={selectedAttributes.current}
                    updateSelection={updateSelection}
                  />
                )}
                {steps.accessories?.step?.available && (
                  <Step
                    data={steps.accessories}
                    car={car}
                    number={i}
                    nextStep={++i}
                    activeStep={activeStep}
                    setActiveStep={updateActiveStep}
                    selectedAttributes={selectedAttributes.current}
                    updateSelection={updateSelection}
                  />
                )}
                {steps.finans?.step?.available && (
                  <Step
                    data={steps.finans}
                    number={i}
                    nextStep={++i}
                    activeStep={activeStep}
                    setActiveStep={updateActiveStep}
                    selectedAttributes={selectedAttributes.current}
                    updateSelection={updateSelection}
                  />
                )}
                {steps.assistance?.step?.available && (
                  <Step
                    data={steps.assistance}
                    number={i}
                    nextStep={++i}
                    activeStep={activeStep}
                    setActiveStep={updateActiveStep}
                    selectedAttributes={selectedAttributes.current}
                    updateSelection={updateSelection}
                  />
                )}
                {steps.offer?.step?.available && (
                  <Step
                    data={Object.assign({}, steps.offer, form)}
                    number={i}
                    activeStep={activeStep}
                    setActiveStep={updateActiveStep}
                    selectedAttributes={selectedAttributes.current}
                    updateSelection={updateSelection}
                    totalCost={totalCost}
                  />
                )}
              </div>
            </div>
            <CarView
              car={car}
              selectedAttributes={selectedAttributes.current}
              carViewImage={carViewImage}
              setCarViewImage={setCarViewImage}
            />
          </div>
          <div className={styles['_footer']}>
            <div className={styles['_information-container-wrapper']}>
              <span
                className={`${styles['_header-wrapper']}
                  ${detailsPanelOpen ? styles['open'] : ''}`}
                onClick={() => setDetailsPanelOpen(!detailsPanelOpen)}>
                <div className={styles['_information-container']}>
                  <div className={styles['_information']}>
                    <TotalPrice
                      title={total?.title}
                      totalCost={totalCost}
                      selectedAttributes={selectedAttributes.current}
                    />
                    <button className={styles['_disclamer']}>{disclamer?.buttonText}</button>
                  </div>
                  <div className={styles['_selections']}>
                    <h3>{detailsPanelOpen ? details?.buttonTextClose : details?.buttonTextOpen}</h3>
                    <ArrowWithCircle color={'black'} />
                  </div>
                </div>
                <div className={styles['_info']}>
                  <h3>Dine valg</h3>
                  <div className={styles['selections']}>
                    {model && (
                      <SelectionListElement
                        type="Modell"
                        title={model.name}
                        subtitle={model.step?.subtitle}
                        price={model.price}
                      />
                    )}
                    {color && (
                      <SelectionListElement
                        type="Farge"
                        title={color.name}
                        subtitle={color.step?.subtitle}
                        price={color.price}
                      />
                    )}
                    {accessories &&
                      Object.entries(accessories).map((val: unknown) => {
                        return (
                          <SelectionListElement
                            key={val[0]}
                            type="Tilbehør"
                            title={val[1].name}
                            subtitle={accessories.step?.subtitle}
                            price={val[1].price}
                          />
                        );
                      })}
                    {finans && (
                      <SelectionListElement
                        type="Finans"
                        title="title"
                        subtitle={finans.step?.subtitle}
                      />
                    )}
                    {assistant && (
                      <SelectionListElement
                        type="Assistanse"
                        title="title"
                        subtitle={assistant.step?.subtitle}
                      />
                    )}
                  </div>
                  <div className={styles['_cost_total']}>
                    <div>
                      <TotalPrice
                        title={total?.title}
                        totalCost={totalCost}
                        selectedAttributes={selectedAttributes.current}
                      />
                    </div>
                  </div>
                  <SquareButton
                    className={styles['_button']}
                    onClick={() => console.log('click')}
                    text="Fortsett tilpassing"
                  />
                  <span className={styles['_disclamer']}>
                    <SimpleBlockContent data={disclamer?.disclamer} />
                  </span>
                </div>
              </span>
            </div>
            <div className={styles['_current-step']}>{`${carViewImage ? 2 : 1}/2`}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuildCar;

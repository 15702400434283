import { toSubaruNumber } from '@utils/convert';
import React, { FunctionComponent } from 'react';

import styles from './totalPrice.module.scss';

type Props = {
  title,
  selectedAttributes;
  totalCost?;
}

const TotalPrice: FunctionComponent<Props> = ({ totalCost, title }) => {
  return (
    <div className={styles['root']}>
      <h4>{title || ''}</h4>
      <div>{`fra ${toSubaruNumber(totalCost || 0)},- NOK *`}</div>
    </div>
  );
};



export default TotalPrice;


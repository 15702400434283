import SimpleBlockContent from '@components/bits/SimpleBlockContent';
import { basicInVewOptions } from '@utils/animations/fadeSettings';
import React, { FunctionComponent } from 'react';
import { useInView } from 'react-intersection-observer';

import Checkbox from './bits/Checkbox';
import styles from './finans.module.scss';

type Props = {
  data;
  selectedAttributes;
  setSelectedAttributes;
};

const Finans: FunctionComponent<Props> = ({
  data,
  selectedAttributes,
  setSelectedAttributes,
}): JSX.Element => {
  const { ref, inView } = useInView(basicInVewOptions);

  const { alternatives, desc, disclamer } = data;
  const updateAttributes = (key, name) => {
    const updated = Object.assign({}, selectedAttributes.finans || {});

    if (updated[key]?.key) {
      delete updated[key];
    } else {
      updated[key] = { key, name };
    }

    const update = Object.assign({}, selectedAttributes, { finans: updated });
    setSelectedAttributes(update);
  };

  return (
    <div ref={ref} className={`${styles.root} ${inView ? styles['in-view'] : ''}`}>
      <div className={styles['desc']}>{desc}</div>
      <div className={styles['alternatives']}>
        {alternatives &&
          alternatives.map((val, key) => {
            return (
              <span key={key} onClick={() => updateAttributes(key.toString(), val)}>
                <Checkbox
                  selected={selectedAttributes?.finans && selectedAttributes?.finans[key]}
                />
                <div>{val}</div>
              </span>
            );
          })}
      </div>
      <span className={styles['disclamer']}>
        <SimpleBlockContent data={disclamer} />
      </span>
    </div>
  );
};

export default Finans;

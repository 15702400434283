import useWindowDimensions from '@utils/hooks/useWindowDimension';
import iub from '@utils/ImageUrlBuilder';
import React, { FunctionComponent } from 'react';

import css from './index.module.scss';
interface Size {
  width: number | undefined;
  height: number | undefined;
}
type Props = {
  image;
  maxHeight?;
  contain?: boolean;
};

const FullscreenImage: FunctionComponent<Props> = ({ image, maxHeight = 'auto', contain }) => {
  const size: Size = useWindowDimensions();

  const hotspot = image?.hotspot || { x: 0.5, y: 0.5 };

  const style = {
    backgroundImage: `url(${iub(image)
      .width(size.width <= 768 ? 800 : 2500)
      .url()})`,
    maxHeight,
    backgroundPosition: hotspot
      ? `${Math.ceil(hotspot.x * 100)}% ${Math.ceil(hotspot.y * 100)}%`
      : 'center center',
    ...(contain && { backgroundSize: 'contain' }),
  };

  return <div className={css['root']} style={style}></div>;
};

export default FullscreenImage;

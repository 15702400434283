import React from 'react';

import styles from './selectedSpot.module.scss';

type Props = {
  selected: boolean;
};

const SelectedSpot = ({ selected = false }: Props): JSX.Element => {
  return <div className={`${styles.root} ${selected ? styles['active'] : ''}`}></div>;
};

export default SelectedSpot;

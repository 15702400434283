import BuildCarContactPlug from '@components/plugs/forms/BuildCarContactPlug';
import React, { FunctionComponent } from 'react';

import styles from './offer.module.scss';

type Props = {
  data;
  selectedAttributes?;
  setSelectedAttributes?;
  totalCost?;
}

const Offer:FunctionComponent<Props> = ({ data, selectedAttributes, totalCost}): JSX.Element => {
  return <div className={styles.root}>
    <BuildCarContactPlug
      {...data}
      totalCost={totalCost}
      content={selectedAttributes}
      description={''}
      title={'title'}
      hideTitle={true}
      color={'white'}/>
  </div>;
};

export default Offer;

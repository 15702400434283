import MediaResolver from '@components/bits/MediaResolver/BackgroundMediaResolver';
import SimpleBlockContent from '@components/bits/SimpleBlockContent';
import SquareButton from '@components/bits/SquareButton';

import styles from './index.module.scss';

type Props = {
  subtitle: string;
  title: string;
  mediaType: TMediaType;
  image: TSanityImage;
  videoURL: string;
  actions;
  textTitle;
  text;
  gradient?;
  hideActions: boolean;
  video: { source: TSanityVideo };
};

const mediaHero: React.FC<Props> = ({
  subtitle,
  title,
  textTitle,
  text,
  mediaType,
  image,
  actions,
  hideActions,
  video,
}) => {
  return (
    <div
      className={`flex justify-center min-h-content-screen-height h-content-screen-height group dark  w-full px-grid-padding mx-auto bg-black`}>
      <div
        className={
          'absolute h-screen w-screen flex flex-col items-end justify-center center-container overflow-hidden'
        }>
        <MediaResolver
          mediaType={mediaType}
          image={image}
          hotspot={true}
          source={video?.source}
          gradient={true}
          videoWrapperStyling={styles['videoWrapper']}
        />
      </div>

      <div className="h-full flex flex-col justify-between desktop:items-center min-h-[550px] items-start max-w-max-content-width w-full">
        <div className={`${styles.content} mt-12 desktop:mt-24`}>
          <header className={styles['_header']}>
            {subtitle && (
              <h3
                className={`${styles.subtitle} font-body scale-100 mb-3 desktop:smallcaps text-white lg-desktop:text-[24px]  text-4 desktop:text-center`}>
                {subtitle}
              </h3>
            )}
            {title && (
              <h2
                className={`font-display scale-100  desktop:smallcaps font-weight-medium text-white lg-desktop:text-[60px]  text-4`}>
                {title}
              </h2>
            )}
          </header>
        </div>
        <div
          className={`${
            !hideActions ? '' : 'desktop:pb-[20px]'
          } flex flex-col spacing desktop:items-start justify-between mb-[8vh] lg-desktop:mb-0 z-[1] w-full desktop:pl-content-column-width`}>
          {textTitle && (
            <h4 className="font-semibold text-white font-body text-base mb-4 tracking-letterSpacing leading-6 lg-desktop:text-[18px]">
              {textTitle}
            </h4>
          )}
          {text && (
            <span className={'text-base text-white mb-11 lg-desktop:text-[18px]'}>
              <SimpleBlockContent data={text} />
            </span>
          )}

          {!hideActions && actions?.length > 0 && (
            // <div className={`${styles.actions} desktop:hidden`}>
            <div className={`${styles.actions} `}>
              <div
                className={`${styles['actions-inner']} flex flex-col max-w-[50vw] min-w-[220px] desktop:flex-row space-y-4 desktop:space-y-0 desktop:space-x-4 desktop:h-[40px] lg-desktop:mb-[120px]`}>
                {actions.map((cta, i) => (
                  <SquareButton
                    key={`actions-${i}`}
                    cta={cta}
                    className={'relative bg-white text-black'}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default mediaHero;

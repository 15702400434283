import CustomInputSearch from '@components/bits/CustomInputSearch';
import SimpleBlockContent from '@components/bits/SimpleBlockContent';
import { basicInVewOptions } from '@utils/animations/fadeSettings';
import { calculateDistance } from '@utils/map/calculateDisctance';
import axios from 'axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import styles from './index.module.scss';

const debouncer = new Subject();

// type Props = {
//   onSubmitted?: any;
// };
type Props = {
  dealers;
  description;
  title;
  privacy;
  hideTitle?: boolean;
  color?: string;
  content?;
  totalCost?;
  form?;
};

const BuildCarContactPlug: FunctionComponent<Props> = ({
  privacy,
  dealers,
  form,
  totalCost,
  content = {},
  description,
  title,
  hideTitle = false,
  color = 'black',
}) => {
  const { ref, inView } = useInView(basicInVewOptions);
  const [success, setSuccess] = useState(false);
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [dealer, setDealer] = useState<{ _id: string; forhandler: string } | unknown>('');
  const [dealerSearch, setDealerSearch] = useState('');
  const [dealerSearchList, setDealerSearchList] = useState([]);
  const [newsLetter, setNewsLetter] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);

  const [emailError, setEmailError] = useState('');
  const [newsletterError, setNewsletterError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [mobileError, setMobileError] = useState('');

  const [dealerError, setDealerError] = useState('');

  const [searchLocation, setSearchLocation] = useState(null);
  const [searchLocationList, setSearchLocationList] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    dealers.sort((a, b) => {
      if (a.forhandler < b.forhandler) {
        return -1;
      }
      if (a.forhandler > b.forhandler) {
        return 1;
      }
      return 0;
    });

    debouncer.pipe(debounceTime(400)).subscribe((value) => searchForType(value));
  }, []);

  useEffect(() => {
    if (searchLocation) {
      let nList = [...dealers].filter((dealer) => dealer.is_dealer === true);

      for (let i = 0; i < nList.length; i++) {
        const lng1 = nList[i].location.lng;
        const lat1 = nList[i].location.lat;
        const lng2 = searchLocation.geometry.coordinates[0];
        const lat2 = searchLocation.geometry.coordinates[1];

        const dist = calculateDistance(lng1, lat1, lng2, lat2, 'K');

        nList[i].travel = {
          distance: dist.toFixed(1), //(Number(dist) / 10).toFixed(2),
          to: searchLocation.text,
        };
      }

      nList.sort((a, b) => {
        if (Number(a.travel.distance) < Number(b.travel.distance)) {
          return -1;
        }
        if (Number(a.travel.distance) > Number(b.travel.distance)) {
          return 1;
        }

        return 0;
      });

      nList = nList.slice(0, 6);

      setSearchLocationList(nList);
    } else if (!searchLocation && searchLocationList) {
      setSearchLocationList([]);
    }
  }, [searchLocation]);

  const searchForType = async (value): Promise<unknown> => {
    setDealerError('');
    try {
      const body = {
        query: value,
      };

      if (!value || value.length < 3) return;

      setSending(true);

      let res = null;

      res = await axios.post('/api/map/getPlaceLocation', body);

      const reatures = res?.data?.features;

      setSearchLocation(reatures[0]);
      setSending(false);
    } catch (err) {
      setSending(false);
      console.log('err', err);
    }
  };

  const selectDealer = (dealer): void => {
    setDealer(dealer);
    setDealerSearch(dealer.forhandler);
  };

  const updateDealers = (ev, reset = false): void => {
    const v = ev.target.value;

    if (reset || v === '') {
      setDealer('');
      setDealerSearch('');
      setDealerSearchList([]);
      setSearchLocation(null);
      setSearchLocationList([]);
      return;
    }

    const elm: HTMLElement =
      ev?.target?.value &&
      document.querySelector("#dealers-list option[value='" + ev.target.value + "']");
    const id = elm?.dataset?.value;

    if (id) {
      const i = dealers.findIndex((x) => x._id === id);
      if (i !== -1) {
        setDealer(dealers[i]);
        setDealerSearch(dealers[i].forhandler);
        setDealerSearch('Nottin will match');
        setSearchLocation('Nottin will match');
        return;
      }
    } else {
      setDealerSearch(v);
      const nd = Array.from([...dealers]);

      let nlist = nd.filter(
        (val) =>
          val.forhandler.toLowerCase().indexOf(ev.target.value.toLowerCase()) !== -1 &&
          val.is_dealer === true
      );
      nlist = nlist.slice(0, 3);

      setDealerSearchList(nlist);
    }

    debouncer.next(ev.target.value);
  };

  if (success) {
    return (
      <div id={'kontakt'} className={styles.root}>
        <div className={styles.container}>
          <h2 style={{ opacity: 1 }}>{'Takk for din forespørsel!'}</h2>
          <p style={{ opacity: 1 }}>Din forhandler vil ta kontakt.</p>
        </div>
      </div>
    );
  }

  const clearErrors = (): void => {
    setDealerError('');
    setEmailError('');
    setNewsletterError('');
    setFirstNameError('');
    setLastNameError('');
    setMobileError('');
  };

  const setfullName = (fullname) => {
    const firstName = fullname.substring(0, fullname.lastIndexOf(' ')); // "72";
    const lastName = fullname.substring(fullname.lastIndexOf(' ') + 1); // "tocirah sneab"

    setName(firstName);
    setLastName(lastName);
  };

  return (
    <div
      id={'kontakt'}
      ref={ref}
      className={`${styles.root} ${color === 'white' ? 'form-white' : ''} ${
        inView ? styles['in-view'] : ''
      }`}>
      <div className={styles.container}>
        {!hideTitle && (
          <>
            <h2>{title || 'Sett title i sanity'}</h2>
            <p> {description || 'Sett description i sanity'} </p>
          </>
        )}

        <form
          id="contact-form"
          onSubmit={async (ev) => {
            ev.preventDefault();

            if (sending) return;

            clearErrors();

            const body = {
              first_name: name,
              last_name: lastName,
              email,
              phone,
              dealer,
              message,
              newsLetter,
              content,
              totalCost,
            };

            try {
              setSending(true);
              const res = await axios.post('/api/loopify/buildcar', body);
              setSending(false);
              if (res.status === 200) {
                setSuccess(true);
              }
            } catch (err) {
              setSending(false);
              const resp = err.response?.data[0]?.message || '';

              if (err.response.data[0]?.context?.label === 'first_name') {
                setFirstNameError('resp');
              } else if (err.response.data[0]?.context?.label === 'last_name') {
                setLastNameError('resp');
              } else if (err.response.data[0]?.context?.label === 'phone') {
                setMobileError('resp');
              } else if (err.response.data[0]?.context?.label === 'email') {
                setEmailError(resp);
              } else if (err.response.data[0]?.context?.label === 'newsLetter') {
                setNewsletterError('resp');
              } else if (err.response.data[0]?.context?.label === 'dealer') {
                setDealerError('resp');
              }
            }
          }}
          className={styles.form}>
          <CustomInputSearch
            lists={[
              { elements: dealerSearchList, header: 'Søk på navn:' },
              {
                elements: searchLocationList,
                header: form.searchPlaceholder || 'Søk på avstand',
                subHeader: `avstand $subHeaderProp km`,
                subHeaderProp: 'travel.distance',
              },
            ]}
            searchHandler={dealerSearch}
            updateOnSearch={updateDealers}
            dealerError={dealerError}
            onSelect={(val) => selectDealer(val)}
            sending={sending}
            smallLoader={true}
            color={color}
          />

          <input
            type="name"
            name="full_name"
            placeholder={'Fullt Navn'}
            defaultValue={name ? name + ' ' + lastName : null}
            onChange={(e) => setfullName(e.target.value)}
            style={{
              backgroundColor: firstNameError !== '' || lastNameError !== '' ? 'pink' : 'white',
            }}
          />
          <input
            type="email"
            name="email"
            placeholder={form?.placeEmail || 'Din-epost'}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              backgroundColor: emailError !== '' ? 'pink' : 'white',
            }}
          />
          <input
            type="tel"
            name="phone"
            placeholder={form?.placePhone || 'Telefonnummer'}
            defaultValue={phone}
            onChange={(e) => setPhone(e.target.value)}
            style={{
              backgroundColor: mobileError !== '' ? 'pink' : 'white',
            }}
          />
          <input
            type="textarea"
            name="message"
            placeholder={form?.message || 'Beskjed til forhandler'}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className={styles.checkbox}>
            <input
              type="checkbox"
              onChange={() => setNewsLetter(!newsLetter)}
              style={{
                backgroundColor: newsletterError !== '' ? 'pink' : newsLetter ? 'black' : 'white',
              }}
            />
            <label htmlFor="nyhetsbrev">{form?.checkbox || 'Jeg ønsker å motta nyhetsbrev'}</label>
          </div>
          <span className={styles.privacy}>
            <SimpleBlockContent data={privacy} />
          </span>
          {sending ? (
            <div id="loading"></div>
          ) : (
            <button type="submit" className={styles.submitBtn}>
              {form?.buttonName || 'Send'}
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default BuildCarContactPlug;

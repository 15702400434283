import CircleArrowLink from '@components/bits/CircleArrowLink';
import ElementsSlider from '@components/bits/ElementsSlider';
import { basicInVewOptions } from '@utils/animations/fadeSettings';
import { getLowerstPrice } from '@utils/filter';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './index.module.scss';

type Props = {
  imageSlider;
};

const Hero: FunctionComponent<Props> = ({ imageSlider: { elements } }) => {
  const [elementsList, setElementsList] = useState(null);
  const [elementID, setElementID] = useState(0);
  const [activateEndAnimation, setActivateEndAnimation] = useState(null);
  const [pageAnim, setPageAnim] = useState(false);
  const { ref, inView } = useInView(basicInVewOptions);

  const carInfo = elementsList && elementsList[elementID]?.car?.content?.carInfo;
  const priceList = elementsList && elementsList[elementID]?.car?.content?.pricelist;
  const priceFrom = priceList && getLowerstPrice(priceList);

  const t = carInfo?.title || carInfo?.name || (elementsList && elementsList[elementID].title);

  const text = (elementsList && elementsList[elementID].text) || '';

  useEffect(() => {
    if (!elements) return;

    let newElements = Array.from(elements);

    if (elements.length === 1) {
      newElements = [...Array(4)].map(() => elements[0]);
    } else if (elements.length === 2) {
      for (let i = 0; i < 2; i++) {
        newElements.push(elements[i]);
      }
    } else if (elements.length === 3) {
      newElements.push(elements[0]);
    }

    setElementsList(newElements);
  }, []);

  useEffect(() => {
    if (!elementsList) return;

    const pageAnim =
      elementsList[elementID]?.slug?.route?.page?.content[0]?._type === 'teaser' ? true : false;

    setPageAnim(pageAnim);
  }, [elementsList]);

  const gotoPage = (): void => {
    const elm = carInfo || elementsList[elementID].slug;
    setActivateEndAnimation(elm);
  };

  return (
    <div
      ref={ref}
      className={`${styles.root} ${inView ? styles['in-view'] : ''} ${
        activateEndAnimation ? styles['animEnd'] : ''
      } ${pageAnim ? styles['pageAnim'] : ''}`}>
      {elementsList && (
        <ElementsSlider
          elements={elementsList}
          activeElement={elementID}
          setElementID={setElementID}
          pageAnim={pageAnim}
          activateEndAnimation={activateEndAnimation}
        />
      )}

      <span>
        <div className={`${styles.desc} `}>
          <span>
            <span>
              <h4>{t}</h4>

              {priceFrom ? <p>{`Pris fra ${priceFrom},-`}</p> : <p>{text}</p>}
            </span>
            <div className={styles.cta}>
              <CircleArrowLink color={'white'} text={` `} onClick={gotoPage} />
            </div>
          </span>
        </div>
      </span>
    </div>
  );
};

export default Hero;

import React, { FunctionComponent } from 'react';
type Props = {
  color?
}
const MapMarker:FunctionComponent<Props> = ({ color = 'white' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" fill="none" viewBox="0 0 9 12">
      <path
        fill={color}
        d="M4.4 12L.674 6.733A4.397 4.397 0 010 4.399C0 1.973 1.973 0 4.4 0c2.425 0 4.399 1.973 4.399 4.4 0 .815-.23 1.616-.663 2.315L4.399 12zM4.4.733A3.67 3.67 0 00.732 4.4c0 .68.191 1.347.553 1.93L4.4 10.73l3.126-4.42a3.64 3.64 0 00.54-1.91A3.67 3.67 0 004.4.732z"></path>
      <path
        fill={color}
        d="M4.4 6.233a1.835 1.835 0 01-1.834-1.834c0-1.01.823-1.833 1.833-1.833 1.011 0 1.834.823 1.834 1.833 0 1.011-.823 1.834-1.834 1.834zm0-2.933c-.607 0-1.1.493-1.1 1.1 0 .606.493 1.1 1.1 1.1.606 0 1.1-.494 1.1-1.1 0-.607-.494-1.1-1.1-1.1z"></path>
    </svg>
  );
}

export default React.memo(MapMarker);

import FullscreenImage from '@components/bits/FullscreenImage';
import useWindowDimensions from '@utils/hooks/useWindowDimension';
import React, { FunctionComponent } from 'react';

type Props = {
  image;
  margins: {
    top: number;
    bottom: number;
  };
};

interface Size {
  width: number | undefined;
  height: number | undefined;
}

const ImagePlug: FunctionComponent<Props> = ({ image, margins }) => {
  const size: Size = useWindowDimensions();

  const height = size.width / image?.asset?.metadata?.dimensions?.aspectRatio;

  return (
    <div
      style={{
        width: '100%',
        height: `${height}px`,
        marginTop: `${margins ? margins.top : 0}px`,
        marginBottom: `${margins ? margins.bottom : 0}px`,
      }}>
      <FullscreenImage image={image} contain maxHeight={'100%'} />
    </div>
  );
};

export default ImagePlug;

import get from 'lodash/get';
import React, { FunctionComponent } from 'react';

type Props = {
  elements;
  onSelect;
  subHeader: string;
  subHeaderProp;
  header: string;
};
const List: FunctionComponent<Props> = ({
  elements,
  onSelect,
  subHeader = null,
  subHeaderProp = null,
  header = 'trenger navn',
}) => {
  return (
    <>
      <div>
        <h3>{header}</h3>
        <hr />
        <ul>
          {elements.map((val, key) => {
            const shp = subHeaderProp ? get(val, subHeaderProp) : '';
            const sh = subHeader
              ? `${val.adresse} - ${subHeader.replace('$subHeaderProp', shp)}`
              : val.adresse;

            return (
              <li
                key={key}
                data-value={val._id}
                value={val.forhandler}
                onClick={() => onSelect(val)}>
                <span>
                  <h4>{val?.forhandler}</h4>
                  {<p>{sh}</p>}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default List;

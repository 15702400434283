import styles from './Overlay.module.scss';

type Props = {
  opacity?: number;
  children?: React.ReactNode;
};

const Overlay: React.FC<Props> = ({ children, opacity = 0.4 }) => {
  return (
    <div className={styles.root} style={{ '--opacity': opacity }}>
      {children}
    </div>
  );
};

export default Overlay;

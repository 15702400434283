import CloseXButton from '@components/bits/icons/CloseXButton';
import SimpleBlockContent from '@components/bits/SimpleBlockContent';
import SquareButton from '@components/bits/SquareButton';
import { toSubaruNumber } from '@utils/convert';
import React from 'react';

import styles from './description.module.scss';

type Props = {
  element;
  setInfoPanelOpen;
  updateSelection;
};

const Description = ({ element, setInfoPanelOpen, updateSelection }: Props): JSX.Element => {
  const { price, info, _key } = element;
  return (
    <div className={styles.root}>
      <div className={styles['_wrapper']}>
        <div className={styles['_header-content']}>
          <button className={styles['_close']} onClick={() => setInfoPanelOpen(null)}>
            <CloseXButton color={'black'} onClick={() => setInfoPanelOpen(null)} />
          </button>
        </div>
        <div className={styles['_content']}>
          <div className={styles['_header']}>{info?.title}</div>
          <div className={styles['_sub-title']}>{info?.subTitle}</div>
          <div className={styles['_price']}>{`fra ${toSubaruNumber(price)},- NOK *`}</div>
          <div className={styles['_info']}>
            {info?.info && <SimpleBlockContent data={info.info} />}
          </div>
          <p className={styles['_disclamer']}>{info.disclamer}</p>
          <div className={styles['_buttons']}>
            {info?.buttonText && (
              <SquareButton
                text={info.buttonText}
                onClick={() => {
                  setInfoPanelOpen(null);
                  updateSelection({
                    model: {
                      key: _key,
                      price,
                      name: info.subTitle,
                    },
                  });
                }}
              />
            )}
            {info?.returnButton && (
              <button onClick={() => setInfoPanelOpen(null)}>{info.returnButton}</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;

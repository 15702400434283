import { basicInVewOptions } from '@utils/animations/fadeSettings';
import useWindowDimensions from '@utils/hooks/useWindowDimension';
import iub from '@utils/ImageUrlBuilder';
import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import ReactPlayer from 'react-player';

import styles from './gtx.module.scss';

interface Size {
  width: number | undefined;
  height: number | undefined;
}

type Props = {
  currentRow;
  videoURL;
  rowID;
  image;
  car;
  classname;
  size;
}

const Gfx:FunctionComponent<Props> = ({ currentRow, videoURL, rowID, image, car }) => {
  const [classname, setClassname] = useState('');
  const size: Size = useWindowDimensions();
  const [s, setStyles] = useState({});
  const { ref, inView } = useInView(basicInVewOptions);

  useEffect(() => {
    const img = image || car?.content?.carInfo?.figure || '';
    const { hotspot } = img;

    const style = {
      backgroundImage: `url(${iub(img)
        .width(size.width <= 768 ? 800 : 2500)
        .url()})`,
      backgroundPosition: hotspot
      ? `${Math.ceil(hotspot.x * 100)}% ${Math.ceil(hotspot.y * 100)}%`
      : 'center center',

    };

    setStyles(style)
  }, [size])


  useEffect(() => {
    let c = '';

    if (!currentRow) return;

    switch (rowID) {
      case currentRow.curr:
        c = styles['_image'];
        break;
    case currentRow.nextId:
      c = styles['_next-image'];
      break;
    case currentRow.previous:
        c = styles['_prev-image'];
      break;
    case currentRow.previousGutter:
        c = styles['_prev-image-gutter'];
        break;
      default:
        c = styles['_hidden']
    }

    setClassname(c)

  }, [currentRow])

  if (!videoURL) {
    return (
      <span ref={ref} className={classname} ><span style={s} /></span>
    );
  }

  return (
    <span>

<span ref={ref} className={classname}><ReactPlayer
      url={videoURL}
      height={'100%'}
      width={'100%'}
      loop={true}
      playing={true}
      playsinline={currentRow.curr === rowID && inView}
      muted={true}
      // onReady={() =>setPlaying(true)}
      /></span>
    </span>

  )

};

const Memosed = memo(Gfx, (prevProps, nextProps) => {
  const check1 = prevProps.currentRow === nextProps.currentRow;
  const check2 = prevProps.classname === nextProps.classname;
  const check3 = prevProps.size === nextProps.size;

  return check1 === true && check2 === true && check3 === true
})

export default Memosed;

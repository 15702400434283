import InteractiveImage from '@components/bits/InteractiveImage';
import { basicInVewOptions } from '@utils/animations/fadeSettings';
import React, { FunctionComponent, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './assistant.module.scss';
import SelectedSpot from './bits/SelectedSpot';

type Props = {
  data;
  selectedAttributes;
  setSelectedAttributes;
};

const Assistant: FunctionComponent<Props> = ({
  data,
  selectedAttributes,
  setSelectedAttributes,
}): JSX.Element => {
  const { ref, inView } = useInView(basicInVewOptions);
  const key = 'assistant_0';
  const { checkbox, desc, image, preselcted } = data;

  useEffect(() => {
    if (preselcted) {
      updateAttributes();
    }
  }, []);

  const updateAttributes = () => {
    const ref = Object.assign({}, selectedAttributes || {});

    let update = {};

    if (ref.assistant && Object.keys(ref.assistant).length) {
      update = { assistant: {} };
    } else {
      update = { assistant: { key } };
    }

    setSelectedAttributes(update);
  };

  return (
    <div ref={ref} className={`${styles.root} ${inView ? styles['in-view'] : ''}`}>
      {image && <InteractiveImage image={image} interactive={false} width={800} height={800} />}
      <div className={styles['desc']}>{desc}</div>
      <div className={styles['_checkbox-wrapper']} onClick={updateAttributes}>
        <span>
          <SelectedSpot selected={selectedAttributes?.assistant?.key === key} />
          <div className={styles['name']}>{checkbox}</div>
        </span>
        <div>{'included'}</div>
      </div>
    </div>
  );
};

export default Assistant;

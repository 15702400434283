import { useGesture } from '@use-gesture/react';
import router from 'next/router';
import PropTypes from 'prop-types';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import ArrowWithCircle from '../icons/ArrowWithCircle';
import Element from './Element';
import css from './index.module.scss';
import styles from './index.module.scss';
type Props = {
  elements?;
  activeElement;
  setElementID;
  activateEndAnimation;
  pageAnim: boolean;
};

type CurrentRowProps = {
  curr: number;
  nextId: number;
  previous: number;
  previousGutter: number;
};

const getCurrentRow = (id, elements): CurrentRowProps | null => {
  if (!elements) return null;

  const order = {
    curr: id,
    nextId: id + 1 < elements.length ? id + 1 : 0,
    previous: id - 1 >= 0 ? id - 1 : elements.length - 1,
    previousGutter:
      id - 2 >= 0
        ? id - 2
        : elements.length >= 3
        ? elements.length - 2 + id
        : elements.length >= 2
        ? elements.length - 1 + id
        : 0,
  };
  return order;
};

const fpsInterval = 1000 / 60;
// TODO: move these to an ref
// let animsStart = 0;
// let endAnimsStart = 0;
//

const easeOutQuad = (x: number): number => {
  return 1 - (1 - x) * (1 - x);
};

const ElementsSlider: FunctionComponent<Props> = ({
  elements,
  activeElement,
  setElementID,
  activateEndAnimation,
  pageAnim,
}) => {
  const [currentRow, setCurrentRow] = useState(getCurrentRow(activeElement, elements));
  const [loadGutterImages, setLoadGutterImages] = useState(true);
  const [animating, setAnimating] = useState(false);
  const [animateTo, setAnimateTo] = useState({ x: 0 });
  const animation = useRef(null);
  const images = useRef(null);
  const containerRef = useRef(null);
  const animState = useRef(null);
  const animProgress = useRef({
    x: 0,
    toX: 0,
  });

  useEffect(() => {
    animState.current = {
      animsStart: 0,
      endAnimsStart: 0,
      sliderScale: 0.7,
    };

    return cancelAnimationFrame(animState.current);
  }, []);

  const bind = useGesture(
    {
      onDrag: (state) => {
        const x = state.movement[0];

        const dist = Math.max(
          Math.min(Math.floor(x), images.current.clientWidth),
          -images.current.clientWidth
        );

        images.current.style.transform = `translate3d(${dist}px, 0, 0)`;
        animProgress.current.x = x;
      },
      onDragStart: () => {
        // document.documentElement.style.overflow = 'hidden';
        setLoadGutterImages(true);
      },
      onDragEnd: (state) => {
        const direction = state.movement[0];
        const distance = Math.abs(direction);
        if (distance > window.innerWidth * 0.2) {
          if (direction < 0) {
            setAnimateTo({ x: -images.current.clientWidth });
          } else {
            setAnimateTo({ x: images.current.clientWidth });
          }
        } else {
          setAnimateTo({ x: 0 });
        }
        setAnimating(true);
      },
    },
    {
      drag: {
        threshold: 5,
        axis: 'x',
        preventScroll: true,
        rubberband: true,
      },
    }
  );

  const animate = (): void => {
    const now = performance.now();
    const elapsed = now - animState.current.animsStart;

    if (elapsed > fpsInterval) {
      animState.current.animsStart = now - (elapsed % fpsInterval);
    } else {
      window.requestAnimationFrame(animate);
      return;
    }

    // t: current time, b: begInnIng value, c: change In value, d: duration
    const progress = easeOutQuad(Math.max(animProgress.current.x / animateTo.x, 0.01));

    animProgress.current.x = animateTo.x * progress;

    if (progress <= 0.999) {
      window.requestAnimationFrame(animate);

      images.current.style.transform = `translate3d(${Math.floor(animateTo.x * progress)}px, 0, 0)`;
    } else {
      setAnimating(false);
      setAnimateTo({ x: 0 });
      animProgress.current.x = 0;
      document.documentElement.style.overflowY = 'auto';
      images.current.style.transform = `translate3d(${animateTo.x}px, 0, 0)`;

      if (animateTo.x < 0) {
        nextElement();
      } else if (animateTo.x > 0) {
        prevElement();
      }
    }
  };

  const goNext = (): void => {
    setLoadGutterImages(true);
    setAnimateTo({ x: images.current.clientWidth });
    setAnimating(true);
  };

  const nextElement = (): void => {
    const nextId = currentRow.nextId;
    setElementID(nextId);
  };

  const prevElement = (): void => {
    const nextId = currentRow.previous;
    setElementID(nextId);
  };

  useEffect(() => {
    setCurrentRow(getCurrentRow(activeElement, elements));
    images.current.style.transform = `translate3d(0, 0, 0)`;
  }, [activeElement]);

  useEffect(() => {
    if (animating) {
      animState.current.animsStart = performance.now();
      window.requestAnimationFrame(animate);
    }
  }, [animating]);

  useEffect(() => {
    if (activateEndAnimation) {
      window.scrollTo(0, 0);

      animState.current.endAnimsStart = performance.now();

      setTimeout(
        () => {
          animation.current = window.requestAnimationFrame(endAnim);
        },
        pageAnim ? 1400 : 300
      );
    }
  }, [activateEndAnimation]);

  const endAnim = (): void => {
    const now = performance.now();
    const elapsed = now - animState.current.endAnimsStart;

    if (elapsed > fpsInterval) {
      animState.current.animsStart = now - (elapsed % fpsInterval);
      animState.current.sliderScale = Math.min(1, (animState.current.sliderScale += 0.05));
      // animState.current.sliderScale = 1;
      containerRef.current.style.transform = `translate3d(0, 0, 0) scale3d(${animState.current.sliderScale},${animState.current.sliderScale},${animState.current.sliderScale})`;

      if (animState.current.sliderScale >= 1) {
        cancelAnimationFrame(animState.current);

        let href = null;

        if (activateEndAnimation?.route) {
          href = `/${activateEndAnimation?.route?.slug?.current}`;
        } else if (activateEndAnimation?.link) {
          href = activateEndAnimation.link;
        } else {
          href = activateEndAnimation?.current || activateEndAnimation?.slug?.current;
        }

        if (href) {
          setTimeout(() => {
            router.push(`${href}`, undefined, { shallow: false });
          }, 100);
        }
      } else {
        animation.current = window.requestAnimationFrame(endAnim);
      }
    } else {
      animation.current = window.requestAnimationFrame(endAnim);
    }
  };

  return (
    <div ref={containerRef} className={css['root']} {...bind()}>
      <Element
        ref={images}
        data={elements}
        currentRow={currentRow}
        loadGutterImages={loadGutterImages}
      />

      <span className={styles.cover}></span>

      <div className={styles['_arrow']} onClick={goNext}>
        <ArrowWithCircle />
      </div>
    </div>
  );
};

ElementsSlider.propTypes = {
  elements: PropTypes.array,
};

ElementsSlider.defaultProps = {
  elements: [],
};

export default ElementsSlider;

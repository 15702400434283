import SquareButton from '@bits/SquareButton';
import SimpleBlockContent from '@components/bits/SimpleBlockContent';
import { basicInVewOptions } from '@utils/animations/fadeSettings';
import { triggerEsuvInterestEvent } from '@utils/gtag';
import axios from 'axios';
import React, { FunctionComponent, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './index.module.scss';
type Props = {
  description;
  privacy;
  privacy_2;
  orderTeaserTestDrive;
  title;
};

const TeaserPlug: FunctionComponent<Props> = ({
  description,
  privacy,
  privacy_2,
  orderTeaserTestDrive: {
    buttonName,
    checkbox: checkboxText,
    placeEmail,
    placePhone,
    postalCode,
    successHeader,
    successText,
    completedHeader,
    completedText,
  },
  title,
}) => {
  const { ref, inView } = useInView(basicInVewOptions);

  const [success, setSuccess] = useState(false);
  const [emailRegistered, setEmailRegistered] = useState(false);
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [postal_code, setPostnumber] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setPhone] = useState('');
  const [newsLetter, setNewsLetter] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);

  const [emailError, setEmailError] = useState('');
  const [newsletterError, setNewsletterError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [postalCodeError, setPostalCodeError] = useState('');
  const [mobileError, setMobileError] = useState('');

  const clearErrors = (): void => {
    setEmailError('');
    setNewsletterError('');
    setFirstNameError('');
    setLastNameError('');
    setPostalCodeError('');
    setMobileError('');
  };

  const setfullName = (fullname) => {
    const firstName = fullname.substring(0, fullname.lastIndexOf(' ')); // "72";
    const lastName = fullname.substring(fullname.lastIndexOf(' ') + 1); // "tocirah sneab"

    setFirstName(firstName);
    setLastName(lastName);
  };

  return (
    <div id={'teaser'} ref={ref} className={`${styles.root} ${inView ? styles['in-view'] : ''}`}>
      <div className={styles.container}>
        <span style={{ display: `${!emailRegistered && !success ? 'inline' : 'none'}` }}>
          <h2>{title || 'Sett title i sanity'}</h2>
          <p> {description || 'Sett description i sanity'} </p>

          <form
            id="register-email-form"
            onSubmit={async (ev) => {
              ev.preventDefault();

              if (sending) return;

              clearErrors();

              const body = {
                email,
                newsLetter: newsLetter,
              };

              try {
                setSending(true);
                const res = await axios.post('/api/loopify/teaserEmail', body);
                setSending(false);
                if (res.status === 200) {
                  triggerEsuvInterestEvent();
                  setEmailRegistered(true);
                }
              } catch (err) {
                setSending(false);
                const resp = err.response?.data[0]?.message || '';

                if (err.response.data[0]?.context?.label === 'email') {
                  setEmailError(resp);
                } else if (err.response.data[0]?.context?.label === 'newsLetter') {
                  setNewsletterError('resp');
                }
              }
            }}
            className={styles.form}>
            <input
              type="email"
              name="email"
              style={{
                backgroundColor: emailError !== '' ? 'pink' : 'white',
              }}
              placeholder={emailError !== '' ? emailError : placeEmail || 'Din-epost'}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className={styles.checkbox}>
              <input
                type="checkbox"
                onChange={() => {
                  setNewsletterError('');
                  setNewsLetter(!newsLetter);
                }}
                style={{
                  backgroundColor: newsletterError !== '' ? 'pink' : newsLetter ? 'black' : 'white',
                }}
              />
              <label htmlFor="nyhetsbrev">{checkboxText || 'Jeg ønsker å motta nyhetsbrev'}</label>
            </div>
            <span className={styles.privacy}>
              <SimpleBlockContent data={privacy} />
            </span>
            {sending ? (
              <div id="loading" />
            ) : (
              <button type="submit" className={styles.submitBtn}>
                <SquareButton text={buttonName || 'Send'} overrideText={true} />
              </button>
            )}
          </form>
        </span>
        <span style={{ display: `${emailRegistered && !success ? 'inline' : 'none'}` }}>
          <h2>{successHeader || ''}</h2>
          <p>{successText || ''}</p>
          <form
            id="register-personalia-form"
            onSubmit={async (ev) => {
              ev.preventDefault();

              if (sending) return;

              clearErrors();

              const body = {
                first_name,
                last_name,
                email,
                postal_code,
                mobile,
              };

              try {
                setSending(true);
                const res = await axios.post('/api/loopify/teaser', body);
                setSending(false);
                if (res.status === 200) {
                  setSuccess(true);
                }
              } catch (err) {
                setSending(false);
                const resp = err.response?.data[0]?.message || '';

                if (err.response.data[0]?.context?.label === 'first_name') {
                  setFirstNameError('resp');
                } else if (err.response.data[0]?.context?.label === 'last_name') {
                  setLastNameError('resp');
                } else if (err.response.data[0]?.context?.label === 'postal_code') {
                  setPostalCodeError(resp);
                } else if (err.response.data[0]?.context?.label === 'mobile') {
                  setMobileError('resp');
                }
              }
            }}
            className={styles.form}>
            <input
              type="name"
              name="full_name"
              placeholder={'Fullt Navn'}
              defaultValue={first_name ? first_name + ' ' + last_name : null}
              onChange={(e) => setfullName(e.target.value)}
              style={{
                backgroundColor: firstNameError !== '' || lastNameError !== '' ? 'pink' : 'white',
              }}
            />
            <input
              type="number"
              name="postnumber"
              placeholder={postalCode}
              defaultValue={postal_code}
              onChange={(e) => setPostnumber(e.target.value)}
              style={{
                backgroundColor: postalCodeError !== '' ? 'pink' : 'white',
              }}
            />
            <input
              type="tel"
              name="mobile"
              placeholder={placePhone || 'Mobilnummer'}
              defaultValue={mobile}
              onChange={(e) => setPhone(e.target.value)}
              style={{
                backgroundColor: mobileError !== '' ? 'pink' : 'white',
              }}
            />

            <span className={styles.privacy}>
              <SimpleBlockContent data={privacy_2} />
            </span>
            {sending ? (
              <div id="loading" />
            ) : (
              <button type="submit" className={styles.submitBtn}>
                <SquareButton text={buttonName || 'Send'} overrideText={true} outline={true} />
              </button>
            )}
          </form>
        </span>
        <span style={{ display: `${success ? 'inline' : 'none'}` }}>
          <h2>{completedHeader || ''}</h2>
          <p>{completedText || ''}</p>
        </span>
      </div>
    </div>
  );
};

export default TeaserPlug;

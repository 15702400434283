import { urlForImage } from '@utils/sanity/sanity';
import Image, { ImageLoaderProps, ImageProps } from 'next/legacy/image';

const aspectRatios = {
  portrait: '3/4',
  landscape: '16/9',
  square: '1/1',
};

type aspectKey = keyof typeof aspectRatios;

export const getAspectRatio = (
  image: SanityImage
): { width: number; height: number; aspectRatio: number } => {
  const id = image?.asset?._ref || image?.asset?._id;
  if (!id) return { width: 0, height: 0, aspectRatio: 0 };

  // If using other than original aspect ratio calculate new one
  const [width, height] =
    image.aspectRatio && aspectRatios[image.aspectRatio as aspectKey]
      ? aspectRatios[image.aspectRatio as aspectKey].split('/').map((str: string) => parseInt(str))
      : // Get original dimensions
        id
          .split('-')[2]
          .split('x')
          .map((str: string) => parseInt(str));

  const aspectRatio = height / width;
  return {
    width,
    height,
    aspectRatio,
  };
};

type LoaderProps = ImageLoaderProps & {
  image: SanityImage;
  aspectRatio: number;
};

const loader = ({ width, quality, image, aspectRatio }: LoaderProps): string => {
  return (
    urlForImage(image.asset._id || image.asset._ref)
      .width(width)
      .height(Math.round(width * aspectRatio))
      .quality(quality || 100)
      // .crop('focalpoint')
      // .rect(
      //   image?.crop?.top || image?.crop?.bottom,
      //   image?.crop?.left || image?.crop?.right,
      //   200,
      //   400
      // )
      .auto('format')
      .url() || ''
  );
};

const SanityImage: React.FC<{ image: TSanityImage } & Omit<ImageProps, 'src'>> = ({
  image,
  ...props
}) => {
  if (!image?.asset?._ref && !image?.asset?._id) return null;
  const ID = image?.asset?._ref || image?.asset?._id;
  const { width, height, aspectRatio } = getAspectRatio(image);

  return (
    <Image
      className={`${props.layout === 'fill' ? 'object-cover' : ''}`}
      loader={(props) => loader({ image, ...props, aspectRatio })}
      alt={image.alt}
      // Times 100 has no effect other than removing warnings
      // To my knowledge it is only used to determine aspect ratio
      // The loader takes care of the actual sizing
      {...(props.layout !== 'fill'
        ? {
            width: width * 100,
            height: height * 100,
          }
        : {})}
      // sizes="100vw"
      placeholder="blur"
      blurDataURL={
        urlForImage(ID)
          .width(60)
          .height(Math.round(60 * aspectRatio))
          .quality(50)
          .url()
          ?.toString() || ''
      }
      layout={props.layout || 'responsive'}
      {...props}
      src={urlForImage(ID).width(width).auto('format').url()?.toString() || ''}
    />
  );
};

export default SanityImage;

import useWindowDimensions from '@utils/hooks/useWindowDimension';
import { calculateDistance } from '@utils/map/calculateDisctance';
import { isObject } from '@utils/validate';
import axios from 'axios';
import filter from 'lodash/filter';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import DealerDetails from './DealerDetails';
import DealerList from './DealersList';
import styles from './index.module.scss';

const debouncer = new Subject();

const DynamicComponentWithNoSSR = dynamic(() => import('./Map'), {
  ssr: false,
});

interface Size {
  width: number | undefined;
  height: number | undefined;
}

const checkSearch = (o: [], fields: string[], phrase: string = null): boolean => {
  let match = false;

  if (phrase === null) {
    if (fields.length < 1) {
      match = true;
      return match;
    }

    fields.forEach((field) => {
      if (o[field]) {
        match = true;
        return;
      }
    });
  } else {
    const pString = phrase && phrase.toLowerCase();
    fields.forEach((field) => {
      const oString = o[field] && o[field].toLowerCase();

      if (oString && oString.includes(pString)) {
        match = true;
        return;
      }
    });
  }

  return match;
};

type Props = {
  dealers;
};

const DealersOverview: FunctionComponent<Props> = ({ dealers }) => {
  const [activeDealer, setActiveDealer] = useState(null);
  const [dealerInfoId, setDealerInfoId] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filteredDealers, setFilteredDealers] = useState(dealers);
  const [searchLocation, setSearchLocation] = useState(null);
  const [sending, setSending] = useState<boolean>(false);
  const size: Size = useWindowDimensions();
  const router = useRouter();

  useEffect(() => {
    debouncer.pipe(debounceTime(400)).subscribe((value) => searchForType(value));
  }, []);

  useEffect(() => {
    const loc = String(document.location);
    const params = new URL(loc).searchParams;
    const search = params.get('search');
    const dealer = params.get('dealer');

    if (search || dealer) {
      clearInputs();
      setSending(true);
      setFilterText(search);
      setTimeout(() => {
        if (dealer) {
          setActiveDealer(dealer);
          setSending(false);
        } else {
          changeInputHandler(search);
        }
      }, 500);
    }
  }, []);

  const handleDealerClick = (id: number): void => {
    setActiveDealer(id === activeDealer ? null : id);
    setDealerInfoId(null);
  };

  const showInformationForDealer = (id: number): void => {
    setDealerInfoId(id === dealerInfoId ? null : id);
  };

  const changeInputHandler = (e): void => {
    const value = isObject(e) ? e?.target?.value : e;
    setFilterText(value);
    debouncer.next(value);
  };

  const searchForType = async (value): Promise<void> => {
    // If length 4 and convertable to number .f.ekd 0456, search for postcode
    // else search for place

    try {
      setSending(true);

      const body = {
        query: value,
      };

      if (!value || value.length < 3) return;

      let res = null;

      res = await axios.post('/api/map/getPlaceLocation', body);

      const reatures = res?.data?.features;
      const location = reatures.length ? reatures[0].geometry.coordinates : null;
      setSearchLocation(location);
      setSending(false);
    } catch (err) {
      setSending(false);
      console.log('err', err);
    }
  };

  const changeFilterType = (e): void => {
    const nv = e !== filterType ? e : '';
    setFilterType(nv);
  };

  const clearInputs = (): void => {
    setFilterText('');
    setFilterType('');
    setDealerInfoId(null);
    setSearchLocation(null);
  };
  const findClosest = (): void => {
    setFilterText('');
    setSending(true);

    try {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const location = [pos.coords.longitude, pos.coords.latitude];
          setSending(false);
          setSearchLocation(location);
        },
        () => {
          setSending(false);
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getNearest = (): unknown[] => {
    const dlrs = Array.from(dealers);

    type element = {
      distance: number;
    };
    type Val = {
      location: {
        lat: number;
        lng: number;
      };
    };

    dlrs.forEach((val: Val) => {
      val['distance'] = calculateDistance(
        searchLocation[1],
        searchLocation[0],
        val.location.lat,
        val.location.lng,
        'K'
      );
    });

    const filtered = filter(dlrs, (o: element) => {
      return !o.distance || o.distance <= 100;
    });

    filtered.sort(function (a: element, b: element) {
      return a.distance - b.distance;
    });

    // TODO fix
    return filtered;
  };

  useEffect(() => {
    if (activeDealer) {
      router.push(`/forhandlere?dealer=${activeDealer}`, undefined, { shallow: true });
    }
  }, [activeDealer]);

  useEffect(() => {
    if (filterText) {
      router.push(`/forhandlere?search=${filterText}`, undefined, { shallow: true });
    }

    // IF only nearest, dont filter filtered
    let nearest = [];
    if (searchLocation) {
      nearest = getNearest();
    }

    const filtered = filter(dealers, (o) => {
      if (searchLocation && filterText === '') return null;

      const match = checkSearch(
        o,
        ['county', 'adresse', 'contact_person', 'email', 'forhandler'],
        filterText
      );

      return match;
    });

    const combi = [...filtered, ...nearest];

    const finalFilter = filter(combi, (o) => {
      const fields = [];

      if (filterType === 'Salg') {
        fields.push('is_dealer');
      }
      if (filterType === 'Verksted') {
        fields.push('is_workshop');
      }

      const match = checkSearch(o, fields);
      return match;
    });

    // FILTER OUT DUPLICATES
    const uniqueArray = finalFilter.filter((item, pos) => {
      return finalFilter.indexOf(item) == pos;
    });
    setFilteredDealers(uniqueArray);
  }, [filterText, filterType, searchLocation]);

  const activePage = dealerInfoId ? styles['activePage'] : null;

  return (
    <div className={`${styles.root} ${activePage}`}>
      <DealerList
        dealers={filteredDealers}
        filtered={searchLocation && searchLocation.length}
        activeDealer={activeDealer}
        handleDealerClick={handleDealerClick}
        changeInputHandler={changeInputHandler}
        changeFilterType={changeFilterType}
        filterText={filterText}
        filterType={filterType}
        clearInputs={clearInputs}
        findClosest={findClosest}
        findClosestActive={filterText === '' && searchLocation}
        dealerInfoId={dealerInfoId}
        sending={sending}
        showInformationForDealer={showInformationForDealer}
      />
      <span className={styles.mapContainerWrapper}>
        <div className={`${styles.mapContainer} ${dealerInfoId ? styles['_with-details'] : ''}`}>
          <DynamicComponentWithNoSSR
            dealers={filteredDealers}
            activeDealer={activeDealer}
            handleDealerClick={handleDealerClick}
            dealerInfoId={dealerInfoId}
            searchLocation={searchLocation}
          />
        </div>
        {dealerInfoId && size.width > 768 ? (
          <DealerDetails dealerInfoId={dealerInfoId} dealers={dealers} />
        ) : null}
      </span>
      {dealerInfoId && size.width <= 768 ? (
        <DealerDetails dealerInfoId={dealerInfoId} dealers={dealers} />
      ) : null}
    </div>
  );
};

export default DealersOverview;

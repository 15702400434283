import React, { FunctionComponent, useState } from 'react';

import styles from './index.module.scss';
import List from './List';
type Props = {
  lists;
  searchHandler;
  updateOnSearch;
  dealerError;
  onSelect;
  sending;
  color?;
  smallLoader?;
};

const CustomInputSearch: FunctionComponent<Props> = ({
  lists,
  searchHandler,
  updateOnSearch,
  dealerError,
  onSelect,
  sending = false,
  color = 'black',
  smallLoader = false,
}) => {
  const [showList, setShowList] = useState(false);
  const hasContent = lists.some((val) => val?.elements?.length > 0) && showList;

  const handleFocus = (e): void => {
    setShowList(true);
    updateOnSearch(e, true);
  };

  const handleSelect = (val): void => {
    setShowList(false);
    onSelect(val, true);
  };

  return (
    <span className={`${styles.root} ${color === 'white' ? 'search-white' : ''}`}>
      <label htmlFor="dealer-choice-custom"></label>
      <input
        list="dealers-list-custom"
        id="dealer-choice-custom"
        name="dealer-choice-custom"
        value={searchHandler}
        placeholder="Søk etter nærmeste forhandler"
        style={{
          backgroundColor: dealerError !== '' ? 'pink' : 'white',
        }}
        onChange={updateOnSearch}
        onFocus={handleFocus}
      />
      <div className={styles['_icon']}>
        <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 1L11 7L5 13" stroke="black" />
        </svg>
      </div>
      {hasContent && (
        <span className={styles['search-result-wrapper']}>
          {lists.map((val, key) => {
            if (val.elements.length > 0) {
              return <List key={key} {...val} onSelect={(val) => handleSelect(val)} />;
            }
          })}
        </span>
      )}

      {sending && smallLoader && <div id="loading-small" className="small black"></div>}
      {sending && !smallLoader && <div id="loading" className="small black"></div>}
    </span>
  );
};

export default CustomInputSearch;

import Link from 'next/link';
import React, { FunctionComponent } from 'react';

import ArrowWithCircle from '../icons/ArrowWithCircle';
import styles from './index.module.scss';
type Props = {
  data?;
  color?;
  text?;
  onClick?: () => void;
};

const CircleArrowLink: FunctionComponent<Props> = ({
  data,
  color = 'black',
  text = null,
  onClick = null,
}) => {
  let href = '';
  let title = '';

  if (data?.route) {
    href = `/${data?.route?.slug?.current}`;
  } else if (data?.link) {
    href = data.link;
  } else {
    href = data?.current || data?.slug?.current;
  }

  title = text || data?.title || 'Se mer';

  const dom = (
    <div className={`${styles.root} ${styles[color]}`}>
      <span>{title}</span>
      <ArrowWithCircle color={color} />
    </div>
  );

  if (onClick) {
    return <span onClick={onClick}>{dom}</span>;
  }

  if (!href) return null;

  return (
    <Link href={href} legacyBehavior>
      {dom}
    </Link>
  );
};

export default React.memo(CircleArrowLink);

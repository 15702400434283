import SimpleBlockContent from '@components/bits/SimpleBlockContent';
import { basicInVewOptions } from '@utils/animations/fadeSettings';
import { toSubaruNumber } from '@utils/convert';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import SelectedSpot from './bits/SelectedSpot';
import styles from './models.module.scss';

type Props = {
  data?;
  car;
  selectedAttributes;
  setSelectedAttributes;
  setDisclamerPanelOpen?;
}

const Models:FunctionComponent<Props> = ({ car, selectedAttributes, setSelectedAttributes, setDisclamerPanelOpen}): JSX.Element => {
  const { ref, inView } = useInView(basicInVewOptions);
  const [models, setModels] = useState(null);

  useEffect(() => {
    setModels(car.content.pricelist?.elements);
  }, [])

  useEffect(() => {
    if (models && models?.length && !selectedAttributes.model) {
      setSelectedAttributes({ model: { key: models[0]?._key, name: models[0]?.type, price: models[0]?.price } })
    }
  }, [models])

  const updateAttributes = (key, name, price) => {
    setSelectedAttributes({ model: { key, name, price } })
  }

  const readMore = (e:React.MouseEvent<HTMLElement>, element) => {
    e.preventDefault();
    e.stopPropagation();
    setDisclamerPanelOpen(element)
  }

  return <div ref={ref} className={`${styles.root} ${inView ? styles['in-view'] : ''}`}>
    {models && models.map((val, key) => {
      return <div key={key} className={styles['model']} onClick={() => updateAttributes(val._key, val.type, val.price)}>
        <div className={styles['_header-wrapper']}>
          <SelectedSpot selected={selectedAttributes?.model?.key === val._key} />
          <div className={styles['_header-text']}>
            <h4>{val.type}</h4>
            <div>{`fra ${toSubaruNumber(val.price)},- NOK *`}</div>
          </div>
        </div>
        <h5>inkludert utstyr:</h5>
        <SimpleBlockContent data={val.previewInfo} />
        <button onClick={(e) => readMore(e, val)}>Les mer</button>
      </div>
    }) }
  </div>;
};



export default Models;

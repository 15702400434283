import InteractiveImage from '@components/bits/InteractiveImage';
import { basicInVewOptions } from '@utils/animations/fadeSettings';
import { toSubaruNumber } from '@utils/convert';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import SelectedSpot from './bits/SelectedSpot';
import styles from './colors.module.scss';

type Props = {
  data?;
  car;
  selectedAttributes;
  setSelectedAttributes;
};

const Colors: FunctionComponent<Props> = ({
  car,
  selectedAttributes,
  setSelectedAttributes,
}): JSX.Element => {
  const { ref, inView } = useInView(basicInVewOptions);

  const [colors, setColors] = useState(null);

  useEffect(() => {
    // ignore
    if (selectedAttributes?.model?.name) {
      const filteredColors = car.content.colors.filter((val) => {
        return !val.ignore || (val.ignore && !val.ignore.includes(selectedAttributes?.model?.name));
      });

      if (
        selectedAttributes?.color?.name &&
        !filteredColors.some((val) => {
          return val.color.title === selectedAttributes?.color?.name;
        })
      ) {
        const c = filteredColors[0];
        updateAttributes(c._key, c.color.title, c.color.cost, c.image);
      }

      setColors(filteredColors);
    }
  }, [selectedAttributes]);

  useEffect(() => {
    if (colors && colors?.length && !selectedAttributes.color) {
      setSelectedAttributes({
        color: {
          key: colors[0]?._key,
          name: `${colors[0]?.color.title}`,
          price: colors[0]?.color.cost,
          image: colors[0]?.image,
        },
      });
    }
  }, [colors]);

  const updateAttributes = (key, name, price, image) => {
    setSelectedAttributes({ color: { key, name: `${name}`, price, image } });
  };

  return (
    <div ref={ref} className={`${styles.root} ${inView ? styles['in-view'] : ''}`}>
      {colors &&
        colors.map((val, key) => {
          return (
            <div
              key={key}
              className={styles['color']}
              onClick={() =>
                updateAttributes(val._key, val.color.title, val.color.cost, val.image)
              }>
              <div className={styles['_header-wrapper']}>
                <SelectedSpot selected={selectedAttributes?.color?.key === val._key} />
                <InteractiveImage
                  image={val.previewImage || val.image || car.content.fallbackColorImage}
                  width={100}
                  height={100}
                />
                <div>{val.color.title}</div>
              </div>
              <h5>{`${toSubaruNumber(val?.color?.cost)},- NOK*`}</h5>
            </div>
          );
        })}
    </div>
  );
};

export default Colors;

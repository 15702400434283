import EmployeeBox from '@components/bits/EmployeeBox';
import SimpleArrowButton from '@components/bits/SimpleArrowButton';
import SimpleBlockContent from '@components/bits/SimpleBlockContent';
import SquareButton from '@components/bits/SquareButton';
import { basicInVewOptions } from '@utils/animations/fadeSettings';
import { triggerEmailEvent, triggerMapDirectionEvent, triggerPhoneEvent } from '@utils/gtag';
import useWindowDimensions from '@utils/hooks/useWindowDimension';
import filter from 'lodash/filter';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import CountyList from './CountyList';
import styles from './index.module.scss';
import SearchModule from './SearchModule';

let interval = null;
interface Size {
  width: number | undefined;
  height: number | undefined;
}
type Props = {
  dealers;
  filterText: string;
  activeDealer: number;
  dealerInfoId: number;
  filtered: boolean;
  handleDealerClick;
  showInformationForDealer: (id: number) => void;
  changeInputHandler;
  changeFilterType;
  clearInputs: () => void;
  findClosest: () => void;
  findClosestActive: boolean;
  sending: boolean;
  filterType: string;
};

const DealerList: FunctionComponent<Props> = ({
  dealers,
  activeDealer,
  dealerInfoId,
  handleDealerClick,
  showInformationForDealer,
  changeInputHandler,
  changeFilterType,
  clearInputs,
  findClosest,
  findClosestActive,
  filterText,
  filterType,
  filtered,
  sending,
}) => {
  const [counties, setCounties] = useState(null);
  const data = filter(dealers, (o) => o._id === dealerInfoId)[0];
  const size: Size = useWindowDimensions();
  const [delayedInView, setDelayedInView] = useState(false);
  const { ref, inView } = useInView(basicInVewOptions);

  useEffect(() => {
    if (!filtered) {
      dealers.sort(function (a, b) {
        if (a.forhandler < b.forhandler) {
          return -1;
        }
        if (a.forhandler > b.forhandler) {
          return 1;
        }
        return 0;
      });
    }

    setCounties(dealers);
  }, [dealers]);

  useEffect(() => {
    clearInterval(interval);

    if (inView !== delayedInView) {
      interval = setInterval(() => {
        setDelayedInView(inView);
      });
    }

    return () => clearInterval(interval);
  }, [inView]);

  const getInfo = (): JSX.Element => {
    if (!data) {
      return null;
    }

    return (
      <div className={`${styles['_about-box']}`}>
        <div>
          <h3>{data?.about?.title}</h3>
          <SimpleBlockContent data={data?.about?.text} />
        </div>

        {data?.employeeslist?.elements?.length ? (
          <div>
            <h3>Våre ansatte</h3>
            {data?.employeeslist?.elements &&
              data?.employeeslist?.elements.map((val, key) => {
                return <EmployeeBox key={key} data={val} />;
              })}
          </div>
        ) : null}
      </div>
    );
  };

  const getMapUrl = (lat, lng) => {
    const platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';
    const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(platform);
    const isIOS = /(iPhone|iPod|iPad)/i.test(platform);

    if (isMacLike || isIOS) {
      return `http://maps.apple.com/maps?daddr=${lat},${lng}`;
    }
    return `http://maps.google.com/maps?daddr=${lat},${lng}`;
  };

  const getDetails = (): JSX.Element => {
    if (!data) {
      return null;
    }

    return (
      <div className={`${styles['_info-box']}`}>
        <SimpleArrowButton text={'TILBAKE'} color={'black'} onClick={clearInputs} reverced={true} />
        <div className={styles['_top']} onClick={() => handleDealerClick(data._id)}>
          <h3>{data.forhandler}</h3>
        </div>
        <div className={`${styles['_information']} `}>
          <h5>BESØKSADRESSE</h5>
          <h3>{data.forhandler}</h3>
          <h3>{`${data.adresse}`}</h3>
          <a
            href={getMapUrl(data.location.lat, data.location.lng)}
            onClick={() =>
              triggerMapDirectionEvent(getMapUrl(data.location.lat, data.location.lng))
            }>
            Vis veien hit
          </a>
          {data.phonenr && (
            <a
              href={`tel:${data.phonenr}`}
              onClick={() => triggerPhoneEvent(`tel:${data.phonenr}`)}>{`+47 ${data.phonenr}`}</a>
          )}
          {data.email && (
            <a
              href={`mailto:${data.email}`}
              onClick={() => triggerEmailEvent(`mailto:${data.email}`)}>
              {data.email}
            </a>
          )}
          {data.email ? (
            <SquareButton
              text={'KONTAKT OSS'}
              onClick={() => {
                const mailString = `mailto:${data.email}?subject=Hei ${
                  data.forhandler
                }&body=${'Jeg vil gjerne prøve en subaru'};`;
                const mail = window.open(mailString, '_self');
                mail.close();
                triggerEmailEvent(`mailto:${data.email}`);
              }}
            />
          ) : null}
          {data.url ? (
            <SquareButton
              text={'VÅR FORHANDLERSIDE'}
              onClick={() => {
                window.open(data.url);
              }}
            />
          ) : null}
        </div>
      </div>
    );
  };

  let hStyle = {};

  if (size.width > 768) {
    hStyle = { maxHeight: dealerInfoId ? '100%' : '110vh' };
  }

  return (
    <div
      ref={ref}
      className={`${styles.root} ${delayedInView ? styles['in-view'] : ''}`}
      style={hStyle}>
      <span className={styles['_wrapper']}>
        {!dealerInfoId ? (
          <>
            <SearchModule
              changeInputHandler={changeInputHandler}
              filterText={filterText}
              filterType={filterType}
              changeFilterType={changeFilterType}
              clearInputs={clearInputs}
              findClosest={findClosest}
              sending={sending}
              findClosestActive={findClosestActive}
            />
            <span className={styles['_dealers-wrapper']} id="dealers-scroll-wrapper">
              {/* {Object.keys(counties).map((key) => {
                return ( */}
              {counties && (
                <CountyList
                  // countyName={'key'}
                  dealers={counties}
                  activeDealer={activeDealer}
                  handleDealerClick={handleDealerClick}
                  showInformationForDealer={showInformationForDealer}
                />
              )}

              {/* })} */}
            </span>
          </>
        ) : (
          <span className={styles.detailsContainer}>
            {getDetails()}
            {getInfo()}
          </span>
        )}
      </span>
    </div>
  );
};

export default DealerList;

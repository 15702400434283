import filter from 'lodash/filter';
import React, { FunctionComponent } from 'react';

import styles from './index.module.scss';

type Props = {
  dealerInfoId: string;
  dealers;
};

const DealerDetails: FunctionComponent<Props> = ({ dealerInfoId, dealers }) => {
  const getDetails = (): JSX.Element => {
    const data = filter(dealers, (o) => o._id === dealerInfoId)[0];

    const { openingHours, is_dealer, is_workshop } = data;

    if (openingHours) {
      return (
        <span className={styles.root}>
          <div className={styles['_information']}>
            <span>
              <h5>HVA TILBYR VI:</h5>
              {is_dealer && <p>Forhandler</p>}
              {is_workshop && <p>Service</p>}
            </span>
            {openingHours.map((val, key) => {
              return (
                <span key={key}>
                  <h5>{val.title}</h5>
                  {val.text && <p className={styles['_text']}>{val.text}</p>}
                  {val.elements.map((elm, elmKey) => {
                    return (
                      <span key={elmKey}>
                        <p>{elm.title}:</p>
                        <p>{`${elm.opens} - ${elm.closes}`}</p>
                      </span>
                    );
                  })}
                </span>
              );
            })}
          </div>
        </span>
      );
    }

    return (
      <span className={styles.root}>
        <div className={styles['_information']}>
          <span>
            <h5>HVA TILBYR VI:</h5>
            <p>Forhandler</p>
            <p>Service</p>
          </span>
          <span>
            <h5>Åpningstider Salg</h5>
            <span>
              <p>Mandag:</p>
              <p>09:00 - 17:00</p>
            </span>
            <span>
              <p>Tirsdag:</p>
              <p>09:00 - 17:00</p>
            </span>
            <span>
              <p>Onsdag:</p>
              <p>09:00 - 17:00</p>
            </span>
            <span>
              <p>Torsdag:</p>
              <p>09:00 - 17:00</p>
            </span>
            <span>
              <p>Fredag:</p>
              <p>09:00 - 17:00</p>
            </span>
            <span>
              <p>Lørdag:</p>
              <p>09:00 - 17:00</p>
            </span>
          </span>
          <span>
            <h5>Åpningstider VERKSTED</h5>
            <span>
              <p>Mandag:</p>
              <p>09:00 - 17:00</p>
            </span>
            <span>
              <p>Tirsdag:</p>
              <p>09:00 - 17:00</p>
            </span>
            <span>
              <p>Onsdag:</p>
              <p>09:00 - 17:00</p>
            </span>
            <span>
              <p>Torsdag:</p>
              <p>09:00 - 17:00</p>
            </span>
            <span>
              <p>Fredag:</p>
              <p>09:00 - 17:00</p>
            </span>
            <span>
              <p>Lørdag:</p>
              <p>09:00 - 17:00</p>
            </span>
          </span>
        </div>
      </span>
    );
  };

  return <>{getDetails()}</>;
};

export default DealerDetails;

import ArrowDown from '@components/bits/icons/ArrowDown';
import SquareButton from '@components/bits/SquareButton';
import React, { FunctionComponent, useEffect, useState } from 'react';

import styles from './index.module.scss';
import Accessories from './types/Accessories';
import Assistant from './types/Assistant';
import Colors from './types/Colors';
import Finans from './types/Finans';
import Models from './types/Models';
import Offer from './types/Offer';

type Props = {
  car?;
  data;
  number;
  activeStep;
  nextStep?;
  setActiveStep;
  updateSelection;
  selectedAttributes;
  setDisclamerPanelOpen?;
  totalCost?;
};

const Step: FunctionComponent<Props> = ({
  data,
  car,
  number,
  activeStep,
  nextStep,
  setActiveStep,
  selectedAttributes,
  updateSelection,
  setDisclamerPanelOpen,
  totalCost,
}) => {
  const [type, setType] = useState(null);

  const updateSelectedAttributes = (newAttributes) => {
    updateSelection(newAttributes);
  };

  const getType = (type) => {
    switch (type) {
      case 'buildCarModel':
        return (
          <Models
            data={data}
            car={car}
            selectedAttributes={selectedAttributes}
            setSelectedAttributes={updateSelectedAttributes}
            setDisclamerPanelOpen={(elm) => setDisclamerPanelOpen(elm)}
          />
        );
      case 'buildCarColor':
        return (
          <Colors
            data={data}
            car={car}
            selectedAttributes={selectedAttributes}
            setSelectedAttributes={updateSelectedAttributes}
          />
        );
      case 'buildCarAccessories':
        return (
          <Accessories
            data={data}
            toolsList={car?.content?.tools?.toolsList}
            selectedAttributes={selectedAttributes}
            setSelectedAttributes={updateSelectedAttributes}
          />
        );
      case 'buildCarFinans':
        return (
          <Finans
            data={data}
            selectedAttributes={selectedAttributes}
            setSelectedAttributes={updateSelectedAttributes}
          />
        );
      case 'buildCarAssistant':
        return (
          <Assistant
            data={data}
            selectedAttributes={selectedAttributes}
            setSelectedAttributes={updateSelectedAttributes}
          />
        );
      case 'buildCarOffer':
        return (
          <Offer
            data={data}
            selectedAttributes={selectedAttributes}
            setSelectedAttributes={updateSelectedAttributes}
            totalCost={totalCost}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    setType(getType(data._type));
  }, []);

  useEffect(() => {
    if (activeStep === number) {
      const scrollElm = document.getElementById('buildCarScrollContainer');
      scrollElm.scrollTop = 200 + activeStep * 80 - 80;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }, [activeStep]);

  useEffect(() => {
    setType(getType(data._type));
  }, [selectedAttributes, totalCost]);

  const nextPage = () => {
    setActiveStep(nextStep);
  };

  return (
    <div id={data._type} className={styles.root}>
      <div
        className={`${styles['_header--wrapper']} ${activeStep === number ? styles['_open'] : ''}`}
        onClick={() => setActiveStep(number)}>
        <div className={styles['_header-info-wrapper']}>
          <h4>{`Steg ${number}`}</h4>
          <div>{data.step.title}</div>
        </div>
        <div className={styles['_header-info-icon']}>
          <ArrowDown />
        </div>
      </div>
      <div
        className={`${styles['_content'] + ' light'} ${
          activeStep === number ? styles['_open'] : ''
        }`}>
        {type}
        {nextStep && <SquareButton text={'NESTE'} onClick={nextPage} />}
      </div>
    </div>
  );
};

export default Step;

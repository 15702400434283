import InteractiveImage from '@components/bits/InteractiveImage';
import { basicInVewOptions } from '@utils/animations/fadeSettings';
import { toSubaruNumber } from '@utils/convert';
import React, { FunctionComponent } from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './accessories.module.scss';
import SelectedSpot from './bits/SelectedSpot';

type Props = {
  data?; toolsList; selectedAttributes; setSelectedAttributes;
}

const Accessories:FunctionComponent<Props> = ({ toolsList, selectedAttributes, setSelectedAttributes}): JSX.Element => { 
  const { ref, inView } = useInView(basicInVewOptions);
  const updateAttributes = (key, name, price) => {
  

  const update = Object.assign({}, selectedAttributes);

  if (update?.accessories && update?.accessories[key]) {
    delete update.accessories[key]
  } else if (!update.accessories) {
    update.accessories = {}
    update.accessories[key] = { key, name, price } ;
  } else {
    update.accessories[key] = { key, name, price } ;
  }

  setSelectedAttributes(update)
}
    
  return <div ref={ref} className={`${styles.root} ${inView ? styles['in-view'] : ''}`}>
    {toolsList && toolsList.map((val, key) => {
     const check = selectedAttributes?.accessories && selectedAttributes?.accessories[val._key];      
      return <div key={key} className={styles['color']} onClick={() => updateAttributes(val._key, val.name, val.cost)}>
        <div className={styles['_header-wrapper']}>
            <SelectedSpot selected={check} />
            <InteractiveImage image={val.imageOverride} width={100} height={100} />
          {/* // TODO: add fallback image */}
          <div>{val.name}</div>
        </div>
        <h5>{`${toSubaruNumber(val.cost)},- NOK`}</h5>
      </div>
    }) }
  </div>;
};



export default Accessories;

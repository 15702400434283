import { triggerEmailEvent, triggerPhoneEvent } from '@utils/gtag';
import useWindowDimensions from '@utils/hooks/useWindowDimension';
import iub from '@utils/ImageUrlBuilder';
import React, { FunctionComponent } from 'react';

import css from './index.module.scss';

interface Size {
  width: number | undefined;
  height: number | undefined;
}

type Props = {
  data;
};

const EmployeeBox: FunctionComponent<Props> = ({ data }) => {
  const size: Size = useWindowDimensions();
  return (
    <div className={css.root}>
      <div className={css['_image-wrapper']}>
        {data?.image && (
          <img
            alt="profile image"
            src={iub(data.image)
              .width(size.width <= 768 ? 500 : 1000)
              .url()}
          />
        )}
      </div>
      <div className={css['_info-wrapper']}>
        <h2>{data.name}</h2>
        <p>{data.title}</p>
        <a href={`mailto:${data.email}`} onClick={() => triggerEmailEvent(`mailto:${data.email}`)}>
          {data.email}
        </a>
        <a href={`tel:${data.phone}`} onClick={() => triggerPhoneEvent(`tel:${data.phone}`)}>
          {data.phone}
        </a>
      </div>
    </div>
  );
};

export default EmployeeBox;

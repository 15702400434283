// import dynamic from 'next/dynamic';
import { HTMLAttributes, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
// const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });
import ReactPlayer from 'react-player/lazy';

type Props = {
  width?: string;
  height?: string;
  source?: TSanityVideo;
} & HTMLAttributes<HTMLDivElement>;

const Video: React.FC<Props> = ({ source, width = '100%', height = '100%', ...props }) => {
  const [mediaURL, setMediaUrl] = useState<string | null>(null);

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: false,
    rootMargin: '600px 0px 600px 0px',
  });

  useEffect(() => {
    if (source && source.url) {
      setMediaUrl(source.url);
    }
  }, [source]);

  if (!mediaURL) return null;

  return (
    <div ref={ref} {...props}>
      <ReactPlayer
        url={mediaURL}
        className="react-player"
        width={width}
        height={height}
        loop={true}
        playing={inView}
        playsinline={true}
        volume={0.5}
        muted={true}
        onReady={(e) => {
          const player = e.getInternalPlayer();
          player.setAttribute('muted', 'true');
        }}
      />
    </div>
  );
};

export default Video;

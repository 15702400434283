import 'mapbox-gl/dist/mapbox-gl.css';

import InteractiveImage from '@components/bits/InteractiveImage';
import ArrowRight from '@icons/ArrowRight';
import MagnifyingGlass from '@icons/MagnifyingGlass';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useState } from 'react';

import styles from './index.module.scss';

type Props = {
  backgroundImage;
  color;
  title;
  text;
};

const MapPlug: FunctionComponent<Props> = ({ backgroundImage, color, title, text }) => {
  const [search, setSearch] = useState('');

  const router = useRouter();

  return (
    <div className={styles.container}>
      {backgroundImage && <InteractiveImage image={backgroundImage} interactive={false} />}
      <div className="  z-10">
        {title && <h4>{title}</h4>}
        {text && <p>{text}</p>}
        <form
          style={{ backgroundColor: `${color}`, color: `${color === 'black' ? 'white' : 'black'}` }}
          onSubmit={async (ev) => {
            ev.preventDefault();
            router.push(`/forhandlere?search=${search}`, undefined, { shallow: false });
          }}>
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            id="search"
            name="search"
            placeholder={'Søk forhandler'}
          />
          <button type="submit" style={{ backgroundColor: `${color}` }}>
            {search && search.length ? (
              <ArrowRight color={color === 'black' ? 'white' : 'black'} />
            ) : (
              <MagnifyingGlass color={color === 'black' ? 'white' : 'black'} />
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default MapPlug;

import MediaResolver from '@components/bits/MediaResolver/BackgroundMediaResolver';
import Overlay from '@components/bits/Overlay/Overlay';
import SimpleArrowButton from '@components/bits/SimpleArrowButton';
import SimpleBlockContent from '@components/bits/SimpleBlockContent';
import SquareButton from '@components/bits/SquareButton';
import { getLowerstPrice } from '@utils/filter';

import styles from './CarHero.module.scss';

type Props = {
  theme: TTheme;
  subtitle: string;
  title: string;
  car;
  specs;
  hideSpecs?: boolean;
  mediaType: TMediaType;
  image: TSanityImage;
  videoURL: string;
  actions;
  hideActions: boolean;
  video: { source: TSanityVideo };
};

const background = {
  dark: '0,0,0',
  light: '255,255,255',
};

const Specs: React.FC<{ specs: Props['specs'] }> = ({ specs }) => {
  return (
    specs?.length > 0 && (
      <div className={styles['_specs']}>
        <div className={`${styles['_specs-inner']}`}>
          {specs.map((s) => (
            <div className={styles.spec} key={s._key}>
              {s?.title && <h4 className={styles.spec_title}>{s.title}</h4>}
              {s?.description && (
                <div className={styles.spec_desc}>
                  <SimpleBlockContent data={s.description} />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    )
  );
};

const CarHero: React.FC<Props> = ({
  theme = 'light',
  subtitle,
  title,
  car,
  specs,
  hideSpecs,
  mediaType,
  image,
  actions,
  hideActions,
  video,
}) => {
  const pf = getLowerstPrice(car?.content?.pricelist);
  return (
    <div className={`${styles.CarHero} ${theme}`} style={{ '--background': background[theme] }}>
      <div style={{ position: 'relative' }}>
        <Overlay opacity={mediaType === 'video' ? 0.4 : 0.1}>
          <MediaResolver
            mediaType={mediaType}
            image={image}
            source={video?.source}
            videoWrapperStyling={styles['videoWrapper']}
          />
        </Overlay>
        <div className={styles.content}>
          <header className={styles['_header']}>
            {subtitle && <h3 className={`${styles.subtitle} font-body smallcaps`}>{subtitle}</h3>}
            {(title || car?.content?.carInfo?.name) && (
              <h2 className={`font-display smallcaps font-weight-medium`}>
                {title || car.content.carInfo.name}
              </h2>
            )}
            <p className={`${styles.price} font-body`}>Pris fra {pf} NOK*</p>
          </header>
          <div className={styles.desktopSpecs}>{!hideSpecs && <Specs specs={specs} />}</div>
        </div>
      </div>
      <div className={styles.mobileSpecs}>{!hideSpecs && <Specs specs={specs} />}</div>
      {!hideActions && actions?.length > 0 && (
        <div className={styles.actions}>
          <div className={styles['actions-inner']}>
            {actions.map((cta, i) =>
              i === 0 ? (
                <SquareButton key={`actions-${i}`} cta={cta} />
              ) : (
                <SimpleArrowButton
                  key={`actions-${i}`}
                  cta={cta}
                  color={theme === 'light' ? 'black' : 'white'}
                />
                // <div >
                //   <CtaLink cta={cta} />
                // </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CarHero;

import React, { FunctionComponent } from 'react';
type Props = {
  color?
}
const MagnifyingGlass:FunctionComponent<Props> = ({ color = '#fff' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
      <path
        fill={color}
        stroke={color}
        d="M17.13 16.43l-4.869-4.867a6.302 6.302 0 001.632-4.23A6.34 6.34 0 007.56 1a6.34 6.34 0 00-6.333 6.333 6.34 6.34 0 006.333 6.334 6.299 6.299 0 004.23-1.633l4.867 4.868a.333.333 0 10.472-.471zM7.56 13a5.673 5.673 0 01-5.667-5.667A5.673 5.673 0 017.56 1.667a5.673 5.673 0 015.667 5.666A5.673 5.673 0 017.56 13z"></path>
    </svg>
  );
}

export default React.memo(MagnifyingGlass);

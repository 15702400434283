import ArrowDown from '@components/bits/icons/ArrowDown';
import SquareButton from '@components/bits/SquareButton';
import { triggerEmailEvent, triggerMapDirectionEvent, triggerPhoneEvent } from '@utils/gtag';
import useWindowDimensions from '@utils/hooks/useWindowDimension';
import React, { FunctionComponent, useEffect } from 'react';

import styles from './index.module.scss';
type Props = {
  dealers;
  activeDealer;
  handleDealerClick;
  showInformationForDealer;
};

interface Size {
  width: number | undefined;
  height: number | undefined;
}

const CountyList: FunctionComponent<Props> = ({
  dealers,
  activeDealer,
  handleDealerClick,
  showInformationForDealer,
}) => {
  const size: Size = useWindowDimensions();

  useEffect(() => {
    if (activeDealer !== null) {
      const parent = document.getElementById('dealers-scroll-wrapper');
      const elm = document.getElementById(activeDealer);
      if (elm && parent && size.width > 768) {
        setTimeout(() => {
          const topPos = elm.offsetTop;
          parent.scrollTop = topPos;
        }, 50);
      } else if (elm && parent) {
        const topPos = elm.offsetTop;
        const sp = document.documentElement;
        sp.scrollTop = topPos + 200;
      }
    }
  }, [activeDealer]);

  const getMapUrl = (lat, lng) => {
    const platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';
    const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(platform);
    const isIOS = /(iPhone|iPod|iPad)/i.test(platform);

    if (isMacLike || isIOS) {
      return `http://maps.apple.com/maps?daddr=${lat},${lng}`;
    }
    return `http://maps.google.com/maps?daddr=${lat},${lng}`;
  };

  return (
    <div className={styles.root}>
      <ul>
        {dealers.map((val, key) => {
          return (
            <li key={key} id={val._id}>
              <span
                className={`${styles['_box']} ${
                  val._id == activeDealer ? styles['--active'] : ''
                }`}>
                <div className={styles['_top']} onClick={() => handleDealerClick(val._id)}>
                  <h3>{val.forhandler}</h3> <ArrowDown />
                </div>
                <div className={`${styles['_information']} `}>
                  <h5>BESØKSADRESSE</h5>
                  <h3>{`${val.adresse}`}</h3>
                  <a
                    href={getMapUrl(val.location.lat, val.location.lng)}
                    onClick={() =>
                      triggerMapDirectionEvent(getMapUrl(val.location.lat, val.location.lng))
                    }>
                    Vis veien hit
                  </a>
                  {val.phonenr && (
                    <a
                      href={`tel:${val.phonenr}`}
                      onClick={() =>
                        triggerPhoneEvent(`tel:${val.phonenr}`)
                      }>{`Tel: +47 ${val.phonenr}`}</a>
                  )}
                  {val.email && (
                    <a
                      href={`mailto:${val.email}`}
                      onClick={() => triggerEmailEvent(`mailto:${val.email}`)}>
                      {val.email}
                    </a>
                  )}
                  <SquareButton
                    text={'VIS DETALJER'}
                    inverse
                    onClick={() => showInformationForDealer(val._id)}
                    outline={true}
                  />
                </div>
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

// const areEqual = (prevProps, nextProps): any => {
//   /*
//   return true if passing nextProps to render would return
//   the same result as passing prevProps to render,
//   otherwise return false
//   */
//   return prevProps.activeDealer === nextProps.activeDealer;
// };

export default CountyList;

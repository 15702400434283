import SanityImage from '@components/bits/SanityImage/SanityImage';
import Video from '@components/bits/Video/Video';
import { FunctionComponent, HTMLAttributes, useEffect, useState } from 'react';

import InteractiveImage from '../InteractiveImage';
import styles from './MediaResolver.module.scss';

type Props = {
  mediaType: 'image' | 'video' | undefined;
  image: TSanityImage;
  gradient?;
  hotspot?;
  source?: TSanityVideo;
  videoWrapperStyling?: string;
} & HTMLAttributes<HTMLDivElement>;

// TODO: FIX THIS
const BackgroundMediaResolver: FunctionComponent<Props> = ({
  mediaType,
  image,
  gradient,
  videoWrapperStyling = '',
  source,
  hotspot,
  ...props
}) => {
  const [media, setMedia] = useState(null);

  useEffect(() => {
    const media = mediaType
      ? {
          video: <Video className={videoWrapperStyling} source={source} />,
          image: hotspot ? (
            <InteractiveImage image={image} interactive={false} layout="fill" />
          ) : (
            <SanityImage alt={'dummy alt'} image={image} layout="fill" />
          ), // TODO: fix dummt alt
        }[mediaType]
      : null;

    setMedia(media);
  }, [mediaType, image, source, videoWrapperStyling]);

  return (
    <div className={`${styles.media} ${props.className ? props.className : ''}`} {...props}>
      {media}
      {gradient ? (
        <span className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-black via-transparent via-50% to-transparent to-70%"></span>
      ) : null}
    </div>
  );
};

export default BackgroundMediaResolver;

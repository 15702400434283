import React, { FunctionComponent } from 'react';

import styles from './checkbox.module.scss';

type Props = {
  selected: string | boolean;
};

const Checkbox: FunctionComponent<Props> = ({ selected = false }) => {
  return <div className={`${styles.root} ${selected ? styles['active'] : ''}`}></div>;
};

export default Checkbox;

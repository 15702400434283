import React from 'react';

function ArrowDown():JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="13" fill="none" viewBox="0 0 7 13">
      <path
        fill="#000"
        d="M.678 9.81l.567-.548 2.268 2.214L5.78 9.262l.567.548-2.835 2.762L.678 9.81z"></path>
      <path fill="#000" d="M3.914 12.023V0h-.801v12.023h.801z"></path>
    </svg>
  );
}

export default React.memo(ArrowDown);
